import React from 'react'
import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { Layout, AffiliateDisclosure, PlatformsTable, NonCustodialCard } from '../components/common'
import { MetaData } from '../components/common/meta'
import { SIGN_UP_BONUS_HTML, slugBTC } from '../utils/constants';

/**
* Main index page (home page)
*
* Loads all posts from Ghost and uses pagination to navigate through them.
* The number of posts that should appear per page can be setup
* in /utils/siteConfig.js under `postsPerPage`.
*
*/
const Index = ({ data, location, pageContext }) => {
    const { pathname } = location;

    const posts = data.allGhostPost.edges
    let modifiedPathname ;
    if (pathname === '/') {
        modifiedPathname = '/bitcoin-btc-interest/';
    } else {
        modifiedPathname = pathname;
    }

    return (
        <>
            <MetaData
                location={location}
                // image="https://pbs.twimg.com/profile_images/1402039199444848643/bPhq6Rog_400x400.png"
                // smallImageMeta={true}
            />
            <Layout isHome={true} location={location}>
                <div className="container">
                    <article className="content">
                        <PlatformsTable showTabs pathname={modifiedPathname} />
                        <NonCustodialCard />
                        {/* <section className="post-feed">
                            {posts.map(({ node }) => (
                                // The tag below includes the markup for each post - components/common/PostCard.js
                                <PostCard key={node.id} post={node} />
                            ))}
                        </section>
                        <Pagination pageContext={pageContext} /> */}
                        <AffiliateDisclosure />
                    </article>
                </div>
            </Layout>
        </>
    )
}

Index.propTypes = {
    data: PropTypes.shape({
        allGhostPost: PropTypes.object.isRequired,
    }).isRequired,
    location: PropTypes.shape({
        pathname: PropTypes.string.isRequired,
    }).isRequired,
    pageContext: PropTypes.object,
}

export default Index

// This page query loads all posts sorted descending by published date
// The `limit` and `skip` values are used for pagination
export const pageQuery = graphql`
  query GhostPostQuery($limit: Int!, $skip: Int!) {
    allGhostPost(
        sort: { order: DESC, fields: [published_at] },
        limit: $limit,
        skip: $skip
    ) {
      edges {
        node {
          ...GhostPostFields
        }
      }
    }
  }
`
